import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { StaticImage } from "gatsby-plugin-image";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Autoplay, Pagination } from "swiper"

const SlideSlot = () => {
    return (
        <Swiper
            slidesPerView={1.5}
            spaceBetween={10}
            effect={"fade"}
            autoplay={{
                delay: 4000,
                disableOnInteraction: false,
            }}
            pagination={{
                dynamicBullets: true,
                clickable: true,
            }}
            breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
        >
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/ot.jpg" alt="ot-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/pug.jpg" alt="pug-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/qs.jpg" alt="qs-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/tk.jpg" alt="tk-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/hak.jpg" alt="hak-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/ds.jpg" alt="ds-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/fug.jpg" alt="fug-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/bpg.jpg" alt="bpg-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/bole-slot.jpg" alt="bole-slot-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/rlx.jpg" alt="rlx-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/nlc.jpg" alt="nlc-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/pg.jpg" alt="pg-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/joker.jpg" alt="joker-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/cq9.jpg" alt="cq9-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/evo.jpg" alt="evo-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/goldy.jpg" alt="goldy-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/jdb.jpg" alt="jdb-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/jili.jpg" alt="jili-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/netent.jpg" alt="netent-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/playstar.jpg" alt="playstar-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/pmsl.jpg" alt="pmsl-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/rt.jpg" alt="rt-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/sm.jpg" alt="sm-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/slot/v2022/spade.jpg" alt="spade-01" />
            </SwiperSlide>
        </Swiper>
    )
}

export default SlideSlot;