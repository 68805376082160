import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { StaticImage } from "gatsby-plugin-image";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Autoplay, Pagination } from "swiper"

const SlideCasino = () => {
    return (
        <Swiper
            slidesPerView={1.5}
            spaceBetween={10}
            effect={"fade"}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            pagination={{
                dynamicBullets: true,
                clickable: true,
            }}
            breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
        >
            <SwiperSlide>
                <StaticImage src="../images/casino/v2022/we.jpg" alt="we" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/casino/v2022/skywind.jpg" alt="skywind" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/casino/v2022/cherry.jpg" alt="cherry-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/casino/v2022/mpoker.jpg" alt="mpoker-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/casino/v2022/hot-graph.jpg" alt="hot-graph-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/casino/v2022/usa.jpg" alt="usa-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/casino/v2022/ab.jpg" alt="ab-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/casino/v2022/sa.jpg" alt="sa-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/casino/v2022/pretty.jpg" alt="pretty-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/casino/v2022/big-gaming.jpg" alt="big-gaming-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/casino/v2022/dg.jpg" alt="dg-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/casino/v2022/evolution.jpg" alt="evolution-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/casino/v2022/ezugi.jpg" alt="ezugi-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/casino/v2022/kp.jpg" alt="kp-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/casino/v2022/mx.jpg" alt="mx-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/casino/v2022/pm.jpg" alt="pm-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/casino/v2022/pokdeng-km.jpg" alt="pokdeng-km-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/casino/v2022/venus.jpg" alt="venus-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/casino/v2022/wm.jpg" alt="wm-01" />
            </SwiperSlide>
        </Swiper>
    )
}

export default SlideCasino;