import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { StaticImage } from "gatsby-plugin-image";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Autoplay, Pagination } from "swiper"

const SlideSports = () => {
    return (
        <Swiper
            slidesPerView={1.5}
            spaceBetween={10}
            effect={"fade"}
            autoplay={{
                delay: 4000,
                disableOnInteraction: false,
            }}
            pagination={{
                dynamicBullets: true,
                clickable: true,
            }}
            breakpoints={{
                640: {
                  slidesPerView: 1.5,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
        >
            <SwiperSlide>
                <StaticImage src="../images/sport/v2022/m8.jpg" alt="m8" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/sport/v2022/sbo-sport.jpg" alt="sbo-sport" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/sport/v2022/wwbet.jpg" alt="wwbet" />
            </SwiperSlide>
        </Swiper>
    )
}

export default SlideSports;