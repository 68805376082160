import React from "react";
import Layout from "../components/layout";
import Slide from "../components/slide";
import { Container, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import SlideCasino from "../components/slide-casino";
import { Link } from "gatsby";
import SlideSlot from "../components/slide-slot";
import SlideFishing from "../components/slide-fishing";
import SlideSports from "../components/slide-sports";
import Seo from "../components/Seo";
import { Icon } from '@iconify/react';
import userPlus from '@iconify/icons-fa/user-plus';
import loginIcon from '@iconify/icons-entypo/login';

const IndexPageWrapper = styled.div`
  .device-support {
    display: flex;
    list-style-type: none;
    padding-left: 0rem;
    justify-content: center;
      a.popup-button-login {
        background: #11FD0D;
        border: 3px solid #038400;
        box-sizing: border-box;
        border-radius: 0px;
        width: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: italic;
        font-weight: 400;
        font-size: 18px;
        line-height: 33px;
        padding: 10px 0px 10px 0;
        margin: 0px 5px 0px 5px;
        color: #333;
        svg {
          padding-right: 10px;
        }
      }
      a.popup-button-register {
        background: #FF0000;
        border: 3px solid #a10000;
        box-sizing: border-box;
        border-radius: 0px;
        width: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: italic;
        font-weight: 400;
        font-size: 18px;
        line-height: 33px;
        padding: 10px 0px 10px 0;
        margin: 0px 5px 0px 5px;
        color: #333;
        svg {
          padding-right: 10px;
        }
      }
  }
  .text-center {
    text-align: center;
  }
  .img-border-radius {
    border-radius: 4px;
  }
  .how-to {
    padding-bottom: 2em;
    img {
      padding-bottom: 2em;
      border-radius: 4px;
    }
    ol {
    max-width: 350px;
    counter-reset: my-awesome-counter;
    list-style: none;
    padding-left: 40px;
    }
    ol li {
    margin: 0.5rem 0 0.5rem 0;
    counter-increment: my-awesome-counter;
    position: relative;
    }
    ol li::before {
      content: counter(my-awesome-counter);
      color: #fff;
      font-size: 1.2rem;
      font-weight: bold;
      position: absolute;
      --size: 28px;
      left: calc(-1 * var(--size) - 10px);
      line-height: var(--size);
      width: var(--size);
      height: var(--size);
      top: 0;
      background: #FF0000;
      border-radius: 50%;
      text-align: center;
    }
    .gold {
      background: linear-gradient(180deg, #c58e02 80%, rgba(255, 184, 0, 0.3) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 0rem;
      font-weight: 700;
    }
  }
  .slide-game {
    .head-game {
        display: flex;
        align-items: center;
        margin-top: 30px;
        .left {
          flex: 0 0 auto;
          width: 50%;
          text-align: left;
        }
        .right {
          flex: 0 0 auto;
          width: 50%;
          text-align: right;
          a {
            color: #fff;
            background-color: #dc3545!important;
            border-color: #dc3545;
            background: linear-gradient(180deg, #FF0000 0%, rgba(255, 0, 0, 0.5) 97.92%, rgba(255, 0, 0, 0) 100%);
            border-radius: 30px;
            padding: 5px 30px;
            font-size: 1.25rem;
          }
        }
    }
  }
`

const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title="เว็บเดิมพันออนไลน์ ยอดนิยมอันดับ 1 | Kickoffx999"
        description="เว็บเดิมพันออนไลน์ Kickoffx999 ที่สมบูรณ์แบบที่สุด ที่ได้รวบรวมเอาทั้ง Sport Bet & Casino Online เข้ามารวมไว้ในเว็บเดียว และได้รับความนิยมในการใช้บริการสูงสุดในประเทศไทย"
      />
      <Slide />
      <IndexPageWrapper>
        <Container className="py-5">
          <Row>
            <Col md={4}></Col>
            <Col md={4}>
              <ul className="device-support">
                <li>
                  <a className="popup-button-login" href="https://new.kickoffx999.xyz/login" alt="login" rel="noreferrer">
                    <Icon icon={loginIcon} width="30" /> เข้าสู่ระบบ
                  </a>
                </li>
                <li>
                  <a className="popup-button-register" href="https://new.kickoffx999.xyz/register" alt="register" rel="noreferrer">
                    <Icon icon={userPlus} width="40" /> สมัครสมาชิก
                  </a>
                </li>
              </ul>
            </Col>
            <Col md={4}></Col>
          </Row>
          <Row className="pt-3 pb-3">
            <Col md={3}></Col>
            <Col md={6} className="text-center">
              <h1>
                เว็บเดิมพันยอดนิยม <span>สำหรับลูกค้าของเรา</span>
              </h1>
              <p>
                เว็บเดิมพันออนไลน์ Kickoffx999 ที่สมบูรณ์แบบที่สุด
                ที่ได้รวบรวมเอาทั้ง Sport Bet & Casino Online
                เข้ามารวมไว้ในเว็บเดียว
                และได้รับความนิยมในการใช้บริการสูงสุดในประเทศไทย
              </p>
            </Col>
            <Col md={3}></Col>
          </Row>
          <Row>
            <Col md={3} className="mt-3 mb-3">
              <StaticImage
                className="img-border-radius"
                src="../images/hero-e-sports-betting.jpg"
                alt=""
              />
            </Col>
            <Col md={3} className="mt-3 mb-3">
              <StaticImage
                className="img-border-radius"
                src="../images/hero-casino-online.jpg"
                alt=""
              />
            </Col>
            <Col md={3} className="mt-3 mb-3">
              <StaticImage
                className="img-border-radius"
                src="../images/hero-slot-online.jpg"
                alt=""
              />
            </Col>
            <Col md={3} className="mt-3 mb-3">
              <StaticImage
                className="img-border-radius"
                src="../images/hero-fishing-game.jpg"
                alt=""
              />
            </Col>
          </Row>
          <Row>
            <Col md={3} className="mt-3 mb-3">
              <StaticImage
                className="img-border-radius"
                src="../images/hero-e-sports-betting.jpg"
                alt=""
              />
            </Col>
            <Col md={3} className="mt-3 mb-3">
              <StaticImage
                className="img-border-radius"
                src="../images/hero-cock-fight.jpg"
                alt=""
              />
            </Col>
            <Col md={3} className="mt-3 mb-3">
              <StaticImage
                className="img-border-radius"
                src="../images/hero-horse-game.jpg"
                alt=""
              />
            </Col>
          </Row>
        </Container>
        <Container className="py-5">
          <Row>
            <Col md={12} className="text-center py-3">
              <h2>
                วิธีสมัคร <span>ฝาก-ถอน</span>
              </h2>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div className="how-to">
                <StaticImage
                  src="../images/how-to-register-01.jpg"
                  alt="how-to-register"
                />
                <h3>
                  วิธีสมัครสมาชิก <span>ฝากไม่มีขั้นต่ำ</span> ถอนขั้นต่ำเพียง
                  100 บาท
                </h3>
                <p>กรอกข้อมูล</p>
                <ol>
                  <li>ชื่อ - นามสกุล</li>
                  <li>เบอร์โทรศัพท์</li>
                  <li>รหัสผ่าน</li>
                  <li>ธนาคารและเลขบัญชี</li>
                  <li>รู้จักเราช่องทางไหน</li>
                </ol>
                <p className="gold">
                  *บัญชีที่โอนจะต้องเป็นบัญชีเดียวกับบัญชี ที่สมัครมาเท่านั้น
                </p>
                <p>หลังจากโอนแล้วไม่ต้องแจ้งสลิป เงินจะเข้าอัตโนมัติ</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="how-to">
                <StaticImage
                  src="../images/how-to-02.jpg"
                  alt="how-to-deposit"
                />
                <h3>
                  วิธีฝากเงิน <span>อัตโนมัติ</span>
                </h3>
                <ol>
                  <li>เข้าสู่ระบบ</li>
                  <li>กดปุ่มฝากเงิน</li>
                  <li>คัดลอกเลขบัญชีและทำการโอน</li>
                </ol>
                <p className="gold">
                  *บัญชีที่โอนจะต้องเป็นบัญชีเดียวกับบัญชี ที่สมัครมาเท่านั้น
                </p>
                <p>** รอเครดิตเข้าอัตโนมัติ **</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="how-to">
                <StaticImage
                  src="../images/how-to-03.jpg"
                  alt="how-to-withdraw"
                />
                <h3>
                  วิธีถอนเงิน <span>อัตโนมัติ</span>
                </h3>
                <ol>
                  <li>เข้าสู่ระบบ</li>
                  <li>กดปุ่มถอนเงิน</li>
                  <li>กรอกจำนวนที่ต้องการถอนรอระบบทำรายการถอนอัตโนมัติ</li>
                </ol>
                <p className="gold">
                  *บัญชีที่โอนจะต้องเป็นบัญชีเดียวกับบัญชี ที่สมัครมาเท่านั้น
                </p>
                <p>** รอเงินเข้าอัตโนมัติ **</p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="py-5 slide-game">
          <Row>
            <Col md={12} className="text-center py-3">
              <h2>
                ค่ายเกมส์ <span>เดิมพันที่เปิดให้บริการ</span>
              </h2>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="head-game">
                <div className="left">
                  <h3>
                    คาสิโน <span>สำหรับลูกค้าเรา</span>
                  </h3>
                </div>
                <div className="right">
                  <Link to="/คาสิโนออนไลน์/">ดูทั้งหมด</Link>
                </div>
              </div>
              <div className="py-3">
                <SlideCasino />
              </div>
            </Col>
            <Col md={12}>
              <div className="head-game">
                <div className="left">
                  <h3>
                    สล็อตและเกมส์ <span>สำหรับลูกค้าเรา</span>
                  </h3>
                </div>
                <div className="right">
                  <Link to="/สล็อต/">ดูทั้งหมด</Link>
                </div>
              </div>
              <div className="py-3">
                <SlideSlot />
              </div>
            </Col>
            <Col md={12}>
              <div className="head-game">
                <div className="left">
                  <h3>
                    เกมส์ตกปลา <span>สำหรับลูกค้าเรา</span>
                  </h3>
                </div>
                <div className="right">
                  <Link to="/เกมยิงปลา/">ดูทั้งหมด</Link>
                </div>
              </div>
              <div className="py-3">
                <SlideFishing />
              </div>
            </Col>
            <Col md={12}>
              <div className="head-game">
                <div className="left">
                  <h3>
                    กีฬา <span>สำหรับลูกค้าเรา</span>
                  </h3>
                </div>
                <div className="right">
                  <Link to="/แทงบอล/">ดูทั้งหมด</Link>
                </div>
              </div>
              <div className="py-3">
                <SlideSports />
              </div>
            </Col>
          </Row>
        </Container>
      </IndexPageWrapper>
    </Layout>
  );
}

export default IndexPage