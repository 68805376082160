import React from "react";
import { Swiper, SwiperSlide } from "swiper/react"
import { StaticImage } from "gatsby-plugin-image";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Autoplay, EffectFade, Pagination } from "swiper"

const Slide = () => {
    return (
        <Swiper
            spaceBetween={30}
            effect={"fade"}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            pagination={{
                dynamicBullets: true,
                clickable: true,
            }}
            modules={[Autoplay, EffectFade, Pagination]}
            className="mySwiper"
        >
            <SwiperSlide>
                <StaticImage src="../images/banner-01.jpg" alt="banner-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/banner-02.jpg" alt="banner-02" />
            </SwiperSlide>
        </Swiper>
    )
}

export default Slide;