import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { StaticImage } from "gatsby-plugin-image";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Autoplay, Pagination } from "swiper"

const SlideFishing = () => {
    return (
        <Swiper
            slidesPerView={1.5}
            spaceBetween={10}
            effect={"fade"}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            pagination={{
                dynamicBullets: true,
                clickable: true,
            }}
            breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
        >
            <SwiperSlide>
                <StaticImage src="../images/fishing/v2022/bole-fish.jpg" alt="bole-fish-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/fishing/v2022/ds-fish.jpg" alt="ds-fish-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/fishing/v2022/cq9-fish.jpg" alt="cq9-fish-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/fishing/v2022/jdb-fish.jpg" alt="jdb-fish-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/fishing/v2022/joker-fish.jpg" alt="joker-fish-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/fishing/v2022/km.jpg" alt="km-01" />
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage src="../images/fishing/v2022/sm-fish.jpg" alt="sm-fish-01" />
            </SwiperSlide>
        </Swiper>
    )
}

export default SlideFishing;